// export const BASE_URL = "http://192.168.1.15/new_laravel_mhq/public/crm/"; // local mukesh
// export const BASE_URL = "http://192.168.1.16/merchhq/public/crm/"; // local pushpa
// export const BASE_URL = "https://newmerchhq.premiumfreshfoods.com/crmapi/"; // live
// export const BASE_URL = "https://script.merchhq.io/crm/"; // develop
export const BASE_URL = "https://backend-final.merchhq.io/crm";

export const URL = {
  LOGIN: "admin/login",
  LOGOUT: "admin-logout",
  CHANGE_PASSWORD: "change-password",
  TOKEN_VERIFY: "admin-token-verify/",
  SET_PASSWORD: "admin-set-password",
  FORGOT_PASSWORD: "admin/forgot-password",

  // ROLE
  ROLE: "role",
  ROLES: "admin/role",
  ACTIVE_ROLES: "active-roles",
  DEPARTMENT_ACTIVE_ROLE: "admin/role/active-list",
  DEPARTMENT_ROLE: "/department-role-lists/",

  // ADMIN
  ADMIN: "admin",

  // CATEGORY
  CATEGORY: "parent-categories",
  CATEGORY_ALL_LIST: "all-parent-categories",
  CATEGORY_ALL_LIST_ACTIVE: "parent-categories-with-paginate/lists",
  // CATEGORY: "category-list",
  CATEGORY_LIST: "category/lists",
  CATEGORY_VIEW: "category-view",
  CATEGORY_VIEW_ACTIVE: "active-clild-category/",
  CATEGORY_PRODUCT_VIEW: "active-child-category-products/",
  CATEGORY_ADD: "category-add",
  CATEGORY_UPDATE: "category-update",
  CATEGORY_STATUS: "category-status",

  // MASTER
  MASTER: "master",
  MASTER_LIST: "admin/master/list",
  MASTER_ACTIVE_LIST: "admin/master/active-list",
  MASTERS: "master/create",

  // VENDOR
  VENDOR_PARENT_CATEGORIES: "parent-categories",
  VENDOR_REGISTER: "vendor-register",

  // TAGS
  TAGS: "tags",
  TAG: "tag",
  TAGS_STATUS: "tag-status/",
  TAGS_LIST: "admin/tag/list",
  TAGS_PRODUCT: "product-map-tags",

  // NAVIGATION
  NAVIGATION: "navigation",
  NAVIGATIONS: "navigations",
  NAVIGATIONS_lIST: "navigation/without-paginate-lists",
  NAVIGATION_ASSIGN: "navigation/assign-priority",

  // HSN
  HSNS: "admin/hsn/list",
  HSN: "hsn",
  HSN_SEARCH: "admin/hsn/search",

  // EXPORTS
  ADMIN_EXPORTS: "admin/exports",

  // PRODUCTS
  PRODUCTS: "products",
  PRODUCTS_GENERATE_VARIENT: "admin/product/generate-variation",
  PRODUCTS_VARIENT_STORE: "admin/product/store-product-skus",
  PRODUCTS_VARIENT_UPDATE: "admin/product/update-product-skus",
  PRODUCTS_LIST: "admin/products/list",
  PRODUCTS_VENDOR_PRICED_LIST: "admin/vendor-priced-products/list",
  PRODUCTS_LIST_NO_PAGE: "admin/products/list-without-paginate",
  PRODUCT_IMAGES_DELETE: "products/delete-product-image/",
  PRODUCT_REQUEST_STATUS_CHANGE: "product-request-status/",
  STATUS_CHANGE_PRODUCT_REQUEST: "status-change-project-request",

  // DECORATIVE METHOD MASTER
  DECORATIVE_METHOD_LIST_PAGINATE: "admin/decorative-method/list",
  DECORATIVE_METHOD: "decorative-method",
  DECORATIVE_METHOD_STATUS: "decorative-method-status/",
  DECORATIVE_PRICE_EDIT: "decor-price-edit",
  DECORATIVE_PRICE_LIST: "decor-price-list/",
  DECORATIVE_PRICE: "admin-decor-price",

  //DECORATIVE METHOD
  DECORATIVE_ADDED: "admin-store-vendor-decor-price",
  DECORATIVE_LIST: "list-vendor-decor-price",
  DECORATIVE_STATUS: "decorative-method-status/",
  DECORATIVE_VIEW: "vendor-decor-price-show/",
  DECORATIVE_PRICE_STATUS: "decor-price-status/",

  // ATTRIBUTE
  ATTRIBUTE_LIST_PAGINATE: "admin/attribute/list",
  ATTRIBUTE: "attribute",
  ATTRIBUTE_STATUS: "attribute-status/",

  // CURRENCY
  CURRENCYS: "admin/currency/list",
  CURRENCY_ACTIVE: "active/currency",
  CURRENCIES: "currency",
  CURRENCY_LIST: "active/currency",

  //VENTOR-REGISTER
  VENDOR_STORE_REGISTER: "admin/vendor/store",

  // COLORS
  COLORS: "admin/colors/list",
  COLOR: "colors",

  // VENDOR
  VENDORS_LIST: "admin/vendor/list",
  VENDOR_ACTIVE_LIST: "admin/active-vendor/list",
  VENDORS_VIEW: "vendor/profile/",
  VENDORS_EDIT: "vendor/edit-vendor",
  VENDOR_LOGO_DELETE: "vendor-logo-delete",
  VENDOR_MAP_PRO_LIST: "vendor-management/map-product/lists",
  VENDOR_MAP_VIEW: "vendor-management/view-product",
  VENDOR_MAP_PRICE: "vendor-management/map-product-price",
  VENDOR_PRICE_CHANGE: "vendor-management/vendor-accept-product/",
  VENDOR_ACCEPTED_MAP_PRODUCT: "vendor-management/accept-map-product/lists",
  VENDOR_ACCEPTED_VIEW: "vendor-management/view-vendor-price-request/",
  VENDOR_ACCEPTED_STAUTS: "vendor-management/change-approved-request-status",

  //TESTIMONIAL LIST
  TESTIMONIAL_LIST: "testimonial-list",

  //TESTIMONIAL STORE
  TESTIMONIAL_STORE: "testimonial-store",

  //TESTIMONIAL EDIT
  TESTIMONIAL_EDIT: "testimonial-edit",
  //TESTIMONIAL SHOW
  TESTIMONIAL_SHOW: "testimonial-show/",

  //TESTIMONIAL STATUS
  TESTIMONIAL_STATUS: "testimonial-status/",

  //BANNER LIST
  BANNER_LIST: "site-setting-list",

  //BANNER STATUS
  BANNER_STATUS: "site-setting-status/",

  //BANNER STORE
  BANNER_STORE: "site-setting-store",

  //BANNER EDIT
  BANNER_EDIT: "site-setting-edit",

  //BANNER_VIEW
  BANNER_VIEW: "site-setting-show/",

  //FAQ LIST
  FAQ_LIST: "site-setting-list",

  //FAQ STATUS
  FAQ_STATUS: "site-setting-status/",

  //FAQ STORE
  FAQ_STORE: "site-setting-store",

  //FAQ EDIT
  FAQ_EDIT: "site-setting-edit",

  //FAQ_VIEW
  FAQ_VIEW: "site-setting-show/",

  //VENDOR LIST
  VENDOR_LIST: "site-setting-list",

  //VENDOR STATUS
  VENDOR_STATUS: "site-setting-status/",

  //VENDOR STORE
  VENDOR_STORE: "site-setting-store",

  //VENDOR EDIT
  VENDOR_EDIT: "site-setting-edit",

  //VENDOR_VIEW
  VENDOR_VIEW: "site-setting-show/",

  //BLOG_LIST
  BLOG_LIST: "blog-list",
  BLOG_STATUS: "blog-status/",
  BLOG_ADD: "blog-store",
  BLOG_EDIT: "blog-edit",
  BLOG_VIEW: "blog-show/",
  //hq
  HQ_LIST: "site-setting-list",
  HQ_EDIT: "site-setting-edit",
  HQ_VIEW: "site-setting-show/",

  //Department list
  VENDOR_DEPARTMENT: "vendor-department-list",
  //CURRENCY
  CURRENCY: "active/currency",
  // USER-STATUS
  STATUS_LIST: "admin/vendor-user-status/",
  //ADDRESS-STATUS
  ADDRESS_STATUS: "admin/vendor-address-status/",
  ADDRESS_LIST: "admin/vendor-address-list/",
  ADDRESS_ADD: "admin/vendor-address-store",
  ADDRESS_EDIT: "admin/vendor-address-edit",
  //PROFILE
  ADD_PROFILE: "vendor-user-store",
  EDIT_PROFILE: "edit-vendor-user",
  ROLE_LIST: "admin/role/active-list",
  VENDOR_POSITION_CHANGE: "vendor-position-change",
  VENDOR_VIEW_NEW: "vendor/profile/",
  VENDOR_DETAILS_EDIT: "edit-vendor",
  VENDOR_STATUS_LIST: "vendor-status/",

  //VENDOR LIBRARY STORE
  VENDOR_LIBRARY_STORE: "vendor-library-store",

  //VENDOR LIBRARY EDIT
  VENDOR_LIBRARY_EDIT: "vendor-library-edit",

  //VENDOR LIBRARY LIST
  VENDOR_LIBRARY_LIST: "vendor-library-list",
  //EVENT CATEGORY ADD
  EVENT_CATEGORY_STORE: "event-category-store",

  //EVENT CATEGORY EDIT
  EVENT_CATEGORY_EDIT: "event-category-edit",

  //EVENT CATEGORY LIST
  EVENT_CATEGORY_LIST: "event-category-list",

  //EVENT CATEGORY STATUS
  EVENT_CATEGORY_STATUS: "event-category-status/",

  //EVENT CATEGORY ASSIGN
  EVENT_CATEGORY_ASSIGN: "event-category/assign-priority",

  // PRESET
  PRESET_PACK_LIST: "preset-pack-list",
  PRESET_PACK: "preset-pack-store",
  PRESET_PACK_VIEW: "preset-pack-show/",
  PRESET_PACK_EDIT: "preset-pack-edit/",
  PRESET_PACK_STATUS: "preset-pack-status/",
  PRESET_PACK_PRIORITY: "preset-pack-priority",
  FILTER_BY_SKU: "filter-by-sku",

  //PRODUCTSUGGESTION
  PRODUCT_SUGGESTION_LIST: "product-suggest-list",
  PRODUCT_SUGGESTION_STATUS: "product-suggest-status/",
  PRODUCT_SUGGESTION_ADD: "product-suggest-store",
  PRODUCT_SUGGESTION_VIEW: "product-suggestion-show/",
  PRODUCT_SUGGESTION_EDIT: "product-suggest-edit",
  PRODUCT_VENDOR_REQUEST_PRODUCT_LIST: "vendor-requested-product/lists",
  PRODUCT_VENDOR_PRICE_PRODUCT_LIST:
    "vendor-request-price-change-products/lists",
  PRODUCT_VENDOR_PRICE_REQUEST_VIEW: "view-vendor-price-request/",
  ADMIN_VENDOR_PRICE_CHANGE: "admin-change-vendor-product-request-price",
  PRODUCT_SPECIFIC_VENDOR_PRICE: "get-product-specific-vendor-prices/",
  PRODUCTS_CHANGE_CURRENT_STAUTS: "products/change-current-status",
  GET_PRODUCT_VENDOR_PRICE_LIST: "get-product-vendor-prices-lists/",
  ADMIN_ADD_PRODUCT_PRICE: "admin-add-product-price",
  ASSIGN_VENDOR_PRODUCT_PRICE_PRIORITY: "assign-vendor-product-price-priority",
  GET_PRODUCT_DECOR_PRICE_LIST: "get-product-admin-decorative-prices/",
  PRODUCTS_BULK_STATUS: "products/bulk-status/change",

  // DOWNLOAD SAMPLE EXCELS
  DOWNLOAD_SAMPLE_EXCELS: "admin/download/sample-excels",

  // DOWNLOAD ADMIN PROFILE
  DOWNLOAD_ADMIN_PROFILE: "admin/admin-profile/import",

  // DOWNLOAD CATEGORY ROLE
  DOWNLOAD_CATEGORY_ROLE: "admin/category-role/import",

  // DOWNLOAD VENDOR ROLE
  DOWNLOAD_VENDOR_ROLE: "admin/vendor/import",

  // DOWNLOAD HSN ROLE
  DOWNLOAD_HSN_ROLE: "admin/hsn/import",

  // DOWNLOAD Tag
  DOWNLOAD_TAGS: "admin/tags/import",

  // DOWNLOAD EventTag
  DOWNLOAD_EVENT_TAG: "admin/event-tags/import",

  // DOWNLOAD IconTag
  DOWNLOAD_ICON_TAG: "admin/icon-tags/import",

  // DOWNLOAD CATEGORY SETTINGS
  DOWNLOAD_CATEGORY_SETTINGS: "admin/categories/import",

  // DOWNLOAD VENDOR CREATION IMPORT
  DOWNLOAD_VENDOR_CREATION: "admin/vendor/import",

  // DOWNLOAD PACKS IMPORT
  DOWNLOAD_PACKS: "admin/packs/import",

  // DOWNLOAD PRODUCT LIBRARY
  DOWNLOAD_PRODUCT_LIBRARY: "admin/product/import",

  //Vendor Export
  VENDOR_EXPORT: "admin/vendor/export",

  REQUEST_LIST: "product-request-list",
  REQUEST_STORE: "store-product-request",
  REQUEST_STATUS_CHANGE: "product-request-status/",
  REQUEST_VIEW: "product-request-show/",
  REQUEST_EDIT: "product-request-edit",

  //PRICE CHANGE
  PRICE_CHANGE_LIST: "vendor-request-price-change-products/lists",

  // CUSTOMER LIST
  CUSTOMER_LIST: "customer-list",

  // CUSTOMER LIST STATUS
  CUSTOMER_LIST_STATUS: "customer-status/",

  // CUSTOMER VIEW
  CUSTOMER_LIST_VIEW: "customer-show/",

  // CUSTOMER REQUEST LIST
  CUSTOMER_REQUEST_LIST: "project-request-list",

  // CUSTOMER REQUEST LIST VIEW
  CUSTOMER_REQUEST_LIST_VIEW: "project-request-show/",

  // CUSTOMER REQUEST LIST VIEW NON REGISTERED
  CUSTOMER_REQUEST_LIST_VIEW_NON: "non-reg-project-request-list",

  // ADMIN LIST CUSTOMER REQ
  ADMIN_LIST_CUSTOMER_REQ: "admin-list",

  // CUSTOMER REQUEST LIST TAGS
  CUSTOMER_LIST_TAGS: "tags",

  // CUSTOMER REQUEST LIST EDIT
  CUSTOMER_REQUEST_LIST_EDIT_NON: "admin-edit-project-request/",
  //CUSTOMER FILE EDIT
  CUSTOMER_EDIT_FILE: "admin-edit-project-request-files",
  CUSTOMER_EDIT_NOTES: "admin-edit-project-request-notes",

  //SKU CODE
  SKU_LIST: "product-sku-list",
  NEW_CATEGORY: "/active-clild-category/",

  //CUSTOMER PRODUCT STS
  CUSTOMER_PRODUCT_REQUEST_ADD: "admin-add-product-project-request/",
  CUSTOMER_PRODUCT_REQUEST_STATUS: "admin-disable-product-project-request/",

  // Overview Attributes
  OVERVIEW_ATTRIBUTES_ADD: "/master",
  OVERVIEW_ATTRIBUTES_LIST: "/admin/master/list",
  OVERVIEW_ATTRIBUTES_EDIT: "/master/",
  OVERVIEW_ATTRIBUTES_UPDATE: "/master/",
  OVERVIEW_ATTRIBUTES_STATUS: "/master/status/",

  // BOX
  BOX_LIST: "admin/boxes/list",
  BOX: "box-",

  // SHIPPING CHARGE
  SHIPPING_CHARGE: "shipping-charge-",
  SHIPPING_CHARGE_LIST: "admin/shipping_charges/list",

  // VENDOR SCHEDULE LIST
  VENDOR_SCHEDULE_LIST: "vendor-schedule-list",
  VENDOR_SCHEDULE_STORE: "vendor-schedule-store",

  // CUSTOMER REQUEST PROJECT
  CUSTOMER_REJECT_PRO_REQUEST: "rejected-project-request-list",

  // PROJECT
  PROJECT_LIST: "project-list",
  PROJECT_VIEW: "view-project/",
  PROJECT_UPDATE: "update-project",
  PROJECT_GET_LISTS: "get-project-lists",
  PROJECT_MARGIN_REQUSET: "price-margin-request-projects",
  PROJECT_MARGIN_UPDATE: "price-margin-request-update",

  // product task
  PRODUCT_TASK_LIST: "project-task-list",
  PRODUCT_TASK_ADD: "store-project-task",
  PRODUCT_TASK_VIEW: "project-task-view/",
  PRODUCT_TASK_UPDATE: "edit-project-task/",
  PROJECT_STATUS_CHANGE: "project-status-change",
  PRODUCT_STATUS: "change-project-task-status",
  // COMMANDS
  COMMANDS_ADD: "store-comments",
  // Deparment List
  DEPARTMENT_LIST: "get-admins-by-departments",

  // PRESENTATION
  PRESENTATION_ADD: "project-presentation",
  PRESENTATION_STATUS: "status-project-detail",
  PRESENTATION_DELETE: "delete-project-detail/",
  PRESENTATION_ADD_PROJECT: "add-project-detail",
  PRESENTATION_VIEW_PROJECT: "view-project-detail/",
  PRESENTATION_COMMENT_PROJECT: "comment-add-project-detail",
  PRESENTATION_PRICING: "presentation-pricing",
  PRESENTATION_ARTWORK_STATUS: "artwork-status",
  ARTWORK_STORE: "store-artwork",
  ARTWORK_DELETE: "delete-artwork/",
  ADD_PRODUCT_INFO: "add-product-info",

  // POST ACTIVITY
  ACTIVITY_ADD: "store-project-activity",
  ACTIVITY_LIST: "list-project-activity",

  // FILE ADD
  FILES_ADD: "store-project-file",
  FILES_LIST: "list-project-files",
  FILES_EDIT: "edit-project-file/",
  FILE_VIEW: "view-project-file/",
  FILE_DOWNLOAD: "download-project-file/",
  FILE_DELETE: "delete-project-file/",
  FILE_CLIENT: "list-project-client-files",
  FILE_PIN: "/pin-project-activity",
  //

  // STOCK GET
  GET_STOCK: "get-product-stock",

  // ESTIMATE
  ESTIMATE_ADD: "project-estimate",
  ESTIMATE_STATUS: "estimate-status-project-detail",
  ESTIMATE_EDIT: "product-estimate-edit",
  ESTIMATE_SIZE: "product-estimate-size",
  ESTIMATE_TOTAL_UPDATE: "product-total-update",
  ESTIMATE_TAX: "product-gst/",
  ESTIMATE_GST: "estimate-gst",
  ESTIMATE_QTY_ADD: "product-size-update/",
  ESTIMATE_DETAIL: "get-estimate-detail/",
  ESTIMATE_UPDATE: "update-artwork/",
  ESTIMATE_TAX_CHANGE: "estimate-tax",
  ESTIMATE_MARGIN_REQUEST: "estimate-margin-request-update",
  ORDER_CHANGE_PROJECT_DETAIL: "order-change-project-detail",
  ADD_PROJECT_TITTLE: "add-project-detail-title",
  PROJECT_TITLE_EDIT: "edit-project-detail-title/",
  SHIPPING_TAX: "shipping-tax",
  ESTIMATE_DISCOUNT: "estimate-discount",
  PROJECT_DELETE: "delete-project-detail-title/",

  // SALES ORDER
  PROJECT_TRANSACTION: "get-project-transaction-history",
  PROJECT_TRANSACTION_PROOF: "transaction-proof",

  // PROJECT ADDRESS
  PROJECT_ADDRESS_LIST_NO_PAGE: "project-address",
  PROJECT_ADDRESS_ADD: "project-address-store",
  PROJECT_ADDRESS_VIEW: "view-project-address/",
  PROJECT_ADDRESS_UPDATE: "project-address-update/",
  PROJECT_ADDRESS_LIST: "admin/project-address/list",
  PROJECT_ADDRESS_DELETE: "delete-project-address/",
  PROJECT_USER_ADDRESS: "user-address-list/",

  // SHIPPING AND WAREHOUSE
  GET_SHIPPING_BOX_QTY: "get-shipping-box-and-qty",
  SHIPPING_PRICE: "get-shipping-price",
  INVENRTY_PRICE: "get-inventory-price",
  STORE_PROJECT_BOX: "store-project-box",

  // FILES COMMENTS
  FILE_COMMENTS: "store-project-file-activity",
  FILE_CLIENT_COMMENTS: "store-project-client-file-activity",

  // APPORVE AND REJECT
  APPROVE_REJECT: "change-project-detail-status",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const productDetailImg = {
  loop: true,
  autoplay: false,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
